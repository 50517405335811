import { Accessory, Banner, CodeOrders, GlobalPromotion, Order, Product, PromotionCode, Season, User } from "../models/model";
import { AdminState, RootState } from "../models/state";
import { BareActionContext, getStoreBuilder } from "vuex-typex";
import { convertJsonDates } from "@/api/api";
import AdminApi from "@/api/adminApi";
import toaster, { ToastImpl } from "./toaster";

const initialAdminState: AdminState = {
    orders: [],
    codes: [],
    users: [],
    promotions: [],
    banners: [],
    seasons: [],
    roles: [],
    editedAccessory: undefined,
    editedCode: undefined,
    editedOrder: undefined,
    editedProduct: undefined,
    editedPromotion: undefined,
    editedBanner: undefined,
    editedSeason: undefined,
    editedUser: undefined,
    editedCodeOrders: undefined,
    loading: false
};
const b = getStoreBuilder<RootState>().module("admin", initialAdminState);

const loadingGetter = b.read((state: AdminState) => state.loading, "loading");
const ordersGetter = b.read((state: AdminState) => state.orders, "orders");
const codesGetter = b.read((state: AdminState) => state.codes, "codes");
const promotionsGetter = b.read((state: AdminState) => state.promotions, "promotions");
const bannersGetter = b.read((state: AdminState) => state.banners, "banners");
const seasonsGetter = b.read((state: AdminState) => state.seasons, "seasons");
const usersGetter = b.read((state: AdminState) => state.users, "users");
const rolesGetter = b.read((state: AdminState) => state.roles, "roles");
const editedOrderGetter = b.read((state: AdminState) => state.editedOrder, "editedOrder");
const editedAccessoryGetter = b.read((state: AdminState) => state.editedAccessory, "editedAccessory");
const editedCodeGetter = b.read((state: AdminState) => state.editedCode, "editedCode");
const editedProductGetter = b.read((state: AdminState) => state.editedProduct, "editedProduct");
const editedPromotionGetter = b.read((state: AdminState) => state.editedPromotion, "editedPromotion");
const editedBannerGetter = b.read((state: AdminState) => state.editedBanner, "editedBanner");
const editedSeasonGetter = b.read((state: AdminState) => state.editedSeason, "editedSeason");
const editedUserGetter = b.read((state: AdminState) => state.editedUser, "editedUser");
const editedCodeOrdersGetter = b.read((state: AdminState) => state.editedCodeOrders, "editedCodeOrders");

function setLoading(state: AdminState, payload: boolean): void
{
    state.loading = payload;
}

function setOrders(state: AdminState, payload: Order[]): void
{
    state.orders = payload;
}

function setCodes(state: AdminState, payload: PromotionCode[]): void
{
    state.codes = payload;
}

function setPromotions(state: AdminState, payload: GlobalPromotion[]): void
{
    state.promotions = payload;
}

function setBanners(state: AdminState, payload: Banner[]): void
{
    state.banners = payload;
}

function setSeasons(state: AdminState, payload: Season[]): void
{
    state.seasons = payload;
}

function setUsers(state: AdminState, payload: User[]): void
{
    state.users = payload;
}

function setRoles(state: AdminState, payload: string[]): void
{
    state.roles = payload;
}

function setEditedAccessory(state: AdminState, payload: Accessory | undefined): void
{
    state.editedAccessory = payload;
}

function setEditedCode(state: AdminState, payload: PromotionCode | undefined): void
{
    state.editedCode = payload;
}

function setEditedProduct(state: AdminState, payload: Product | undefined): void
{
    state.editedProduct = payload;
}

function setEditedPromotion(state: AdminState, payload: GlobalPromotion | undefined): void
{
    state.editedPromotion = payload;
}

function setEditedBanner(state: AdminState, payload: Banner | undefined): void
{
    state.editedBanner = payload;
}

function setEditedSeason(state: AdminState, payload: Season | undefined): void
{
    state.editedSeason = payload;
}

function setEditedUser(state: AdminState, payload: User | undefined): void
{
    state.editedUser = payload;
}

function setEditedOrder(state: AdminState, payload: Order | undefined): void
{
    state.editedOrder = payload;
}

function setEditedCodeOrders(state: AdminState, payload: CodeOrders | undefined): void {
    state.editedCodeOrders = payload;
}

async function loadOrders(): Promise<boolean>
{
    admin.loading = true;
    const loadedOrders = await AdminApi.getOrders();
    admin.loading = false;

    convertJsonDates(loadedOrders, ['startDate', 'endDate', 'createdOn', 'endedOn', 'depositFlowStartDate']);

    loadedOrders.forEach(o => (o as any).dates = '');
    admin.orders = loadedOrders;

    return true;
}

async function loadEditedOrder(context: BareActionContext<AdminState, RootState>, orderId: string): Promise<boolean>
{
    admin.loading = true;
    const loadedOrder = await AdminApi.getOrderDetails(orderId);
    admin.loading = false;

    if ((loadedOrder as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (loadedOrder as any).error));
        console.log('Error: ' + (loadedOrder as any).error);
        return false;
    }

    convertJsonDates(loadedOrder, ['startDate', 'endDate', 'createdOn', 'endedOn']);

    admin.editedOrder = loadedOrder;

    return true;
}

async function saveOrder(context: BareActionContext<AdminState, RootState>, order: Order): Promise<boolean>
{
    let savedOrder = ("" as any);
    if (order.id) {
        savedOrder = await AdminApi.updateOrder(order);
    } else {
        savedOrder = await AdminApi.addOrder(order);
    }

    if (savedOrder.error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', savedOrder.error));
        console.log('Error: ' + savedOrder.error);
        return false;
    }

    return true;
}

async function updateOrderTreatedStatus(context: BareActionContext<AdminState, RootState>, params: { orderId: string, treated: boolean }): Promise<boolean> {
    const result = await AdminApi.updateOrderTreatedStatus(params.orderId, params.treated);

    if ((result as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (result as any).error));
        console.log('Error: ' + (result as any).error);
        return false;
    }

    return result;
}

async function deleteOrder(context: BareActionContext<AdminState, RootState>, orderId: string): Promise<boolean>
{
    const deletedOrder = await AdminApi.deleteOrder(orderId);

    if ((deletedOrder as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (deletedOrder as any).error));
        console.log('Error: ' + (deletedOrder as any).error);
        return false;
    }

    return true;
}

async function loadCodes(): Promise<boolean>
{
    admin.loading = true;
    const loadedCodes = await AdminApi.getCodes();
    admin.loading = false;

    convertJsonDates(loadedCodes, ['startDate', 'endDate']);

    admin.codes = loadedCodes;

    return true;
}

async function saveCode(context: BareActionContext<AdminState, RootState>, code: PromotionCode): Promise<boolean>
{
    let savedCode = ("" as any);
    if (code.id) {
        savedCode = await AdminApi.updateCode(code);
    } else {
        savedCode = await AdminApi.addCode(code);
    }

    if (savedCode.error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', savedCode.error));
        console.log('Error: ' + savedCode.error);
        return false;
    }

    return true;
}

async function deleteCode(context: BareActionContext<AdminState, RootState>, codeId: string): Promise<boolean>
{
    const deletedCode = await AdminApi.deleteCode(codeId);

    if ((deletedCode as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (deletedCode as any).error));
        console.log('Error: ' + (deletedCode as any).error);
        return false;
    }

    return true;
}

async function loadPromotions(): Promise<boolean>
{
    admin.loading = true;
    const loadedPromotions = await AdminApi.getPromotions();
    admin.loading = false;

    convertJsonDates(loadedPromotions, ['startDate', 'endDate']);

    admin.promotions = loadedPromotions;

    return true;
}

async function savePromotion(context: BareActionContext<AdminState, RootState>, promotion: GlobalPromotion): Promise<boolean>
{
    let savedPromotion = ("" as any);
    if (promotion.id) {
        savedPromotion = await AdminApi.updatePromotion(promotion);
    } else {
        savedPromotion = await AdminApi.addPromotion(promotion);
    }

    if (savedPromotion.error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', savedPromotion.error));
        console.log('Error: ' + savedPromotion.error);
        return false;
    }

    return true;
}

async function deletePromotion(context: BareActionContext<AdminState, RootState>, promotionId: string): Promise<boolean>
{
    const deletedPromotion = await AdminApi.deletePromotion(promotionId);

    if ((deletedPromotion as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (deletedPromotion as any).error));
        console.log('Error: ' + (deletedPromotion as any).error);
        return false;
    }

    return true;
}

async function loadBanners(): Promise<boolean>
{
    admin.loading = true;
    const loadedBanners = await AdminApi.getBanners();
    admin.loading = false;

    convertJsonDates(loadedBanners, ['startDate', 'endDate']);

    admin.banners = loadedBanners;

    return true;
}

async function saveBanner(context: BareActionContext<AdminState, RootState>, banner: Banner): Promise<boolean>
{
    let savedBanner = ("" as any);
    if (banner.id) {
        savedBanner = await AdminApi.updateBanner(banner);
    } else {
        savedBanner = await AdminApi.addBanner(banner);
    }

    if (savedBanner.error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', savedBanner.error));
        console.log('Error: ' + savedBanner.error);
        return false;
    }

    return true;
}

async function deleteBanner(context: BareActionContext<AdminState, RootState>, bannerId: string): Promise<boolean>
{
    const deletedBanner = await AdminApi.deleteBanner(bannerId);

    if ((deletedBanner as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (deletedBanner as any).error));
        console.log('Error: ' + (deletedBanner as any).error);
        return false;
    }

    return true;
}

async function loadSeasons(): Promise<boolean>
{
    admin.loading = true;
    const loadedSeasons = await AdminApi.getSeasons();
    admin.loading = false;

    convertJsonDates(loadedSeasons, ['startDate', 'endDate']);

    admin.seasons = loadedSeasons;

    return true;
}

async function saveSeason(context: BareActionContext<AdminState, RootState>, season: Season): Promise<boolean>
{
    let savedSeason = ("" as any);
    if (season.id) {
        savedSeason = await AdminApi.updateSeason(season);
    } else {
        savedSeason = await AdminApi.addSeason(season);
    }

    if (savedSeason.error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', savedSeason.error));
        console.log('Error: ' + savedSeason.error);
        return false;
    }

    return true;
}

async function deleteSeason(context: BareActionContext<AdminState, RootState>, seasonId: string): Promise<boolean>
{
    const deletedSeason = await AdminApi.deleteSeason(seasonId);

    if ((deletedSeason as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (deletedSeason as any).error));
        console.log('Error: ' + (deletedSeason as any).error);
        return false;
    }

    return true;
}

async function loadUsers(): Promise<boolean>
{
    admin.loading = true;
    const loadedUsers = await AdminApi.getUsers();
    admin.loading = false;

    convertJsonDates(loadedUsers, ['startDate', 'endDate']);

    admin.users = loadedUsers;

    return true;
}

async function loadRoles(): Promise<boolean>
{
    admin.loading = true;
    const loadedRoles = await AdminApi.getRoles();
    admin.loading = false;

    admin.roles = loadedRoles;

    return true;
}

async function saveUser(context: BareActionContext<AdminState, RootState>, user: User): Promise<boolean>
{
    let savedUser = ("" as any);
    if (user.id) {
        savedUser = await AdminApi.updateUser(user);
    } else {
        // savedUser = await AdminApi.addUser(user);
    }

    if (savedUser.error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', savedUser.error));
        console.log('Error: ' + savedUser.error);
        return false;
    }

    return true;
}

async function deleteUser(context: BareActionContext<AdminState, RootState>, userId: string): Promise<boolean>
{
    const deletedUser = await AdminApi.deleteUser(userId);

    if ((deletedUser as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (deletedUser as any).error));
        console.log('Error: ' + (deletedUser as any).error);
        return false;
    }

    return true;
}

async function saveProduct(context: BareActionContext<AdminState, RootState>, product: Product): Promise<boolean>
{
    let savedProduct = ("" as any);
    if (product.id) {
        savedProduct = await AdminApi.updateProduct(product);
    } else {
        savedProduct = await AdminApi.addProduct(product);
    }

    if (savedProduct.error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', savedProduct.error));
        console.log('Error: ' + savedProduct.error);
        return false;
    }

    return true;
}

async function deleteProduct(context: BareActionContext<AdminState, RootState>, productId: string): Promise<boolean>
{
    const deletedProduct = await AdminApi.deleteProduct(productId);

    if ((deletedProduct as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (deletedProduct as any).error));
        console.log('Error: ' + (deletedProduct as any).error);
        return false;
    }

    return true;
}

async function saveAccessory(context: BareActionContext<AdminState, RootState>, accessory: Accessory): Promise<boolean>
{
    let savedAccessory = ("" as any);
    if (accessory.id) {
        savedAccessory = await AdminApi.updateAccessory(accessory);
    } else {
        savedAccessory = await AdminApi.addAccessory(accessory);
    }

    if (savedAccessory.error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', savedAccessory.error));
        console.log('Error: ' + savedAccessory.error);
        return false;
    }

    return true;
}

async function deleteAccessory(context: BareActionContext<AdminState, RootState>, accessoryId: string): Promise<boolean>
{
    const deletedAccessory = await AdminApi.deleteAccessory(accessoryId);

    if ((deletedAccessory as any).error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', (deletedAccessory as any).error));
        console.log('Error: ' + (deletedAccessory as any).error);
        return false;
    }

    return true;
}

async function addRole(context: BareActionContext<AdminState, RootState>, params: { userId: string, role: string }): Promise<boolean>
{
    const addedRole = await AdminApi.addRole(params.userId, params.role);

    if (addedRole.error) {
        toaster.dispatchToast(new ToastImpl('danger', 'Error', addedRole.error));
        console.log('Error: ' + addedRole.error);
        return false;
    }

    return true;
}

const stateGetter = b.state()

const admin = {
    get state(): AdminState { return stateGetter() },

    get loading(): boolean { return loadingGetter() },
    set loading(value: boolean) { this.commitSetLoading(value) },

    get orders(): Order[] { return ordersGetter() },
    set orders(value: Order[]) { this.commitSetOrders(value); },

    get codes(): PromotionCode[] { return codesGetter() },
    set codes(value: PromotionCode[]) { this.commitSetCodes(value); },

    get promotions(): GlobalPromotion[] { return promotionsGetter() },
    set promotions(value: GlobalPromotion[]) { this.commitSetPromotions(value); },

    get banners(): Banner[] { return bannersGetter() },
    set banners(value: Banner[]) { this.commitSetBanners(value); },

    get seasons(): Season[] { return seasonsGetter() },
    set seasons(value: Season[]) { this.commitSetSeasons(value); },

    get users(): User[] { return usersGetter() },
    set users(value: User[]) { this.commitSetUsers(value); },

    get roles(): string[] { return rolesGetter() },
    set roles(value: string[]) { this.commitSetRoles(value); },

    get editedOrder(): Order | undefined { return editedOrderGetter() },
    set editedOrder(value: Order | undefined) { this.commitSetEditedOrder(value); },

    get editedAccessory(): Accessory | undefined { return editedAccessoryGetter() },
    set editedAccessory(value: Accessory | undefined) { this.commitSetEditedAccessory(value); },

    get editedProduct(): Product | undefined { return editedProductGetter() },
    set editedProduct(value: Product | undefined) { this.commitSetEditedProduct(value); },

    get editedPromotion(): GlobalPromotion | undefined { return editedPromotionGetter() },
    set editedPromotion(value: GlobalPromotion | undefined) { this.commitSetEditedPromotion(value); },

    get editedBanner(): Banner | undefined { return editedBannerGetter() },
    set editedBanner(value: Banner | undefined) { this.commitSetEditedBanner(value); },

    get editedSeason(): Season | undefined { return editedSeasonGetter() },
    set editedSeason(value: Season | undefined) { this.commitSetEditedSeason(value); },

    get editedCode(): PromotionCode | undefined { return editedCodeGetter() },
    set editedCode(value: PromotionCode | undefined) { this.commitSetEditedCode(value); },

    get editedUser(): User | undefined { return editedUserGetter() },
    set editedUser(value: User | undefined) { this.commitSetEditedUser(value); },

    get editedCodeOrders(): CodeOrders | undefined { return editedCodeOrdersGetter() },
    set editedCodeOrders(value: CodeOrders | undefined) { this.commitSetEditedCodeOrders(value) },

    commitSetLoading: b.commit(setLoading),
    commitSetOrders: b.commit(setOrders),
    commitSetCodes: b.commit(setCodes),
    commitSetPromotions: b.commit(setPromotions),
    commitSetBanners: b.commit(setBanners),
    commitSetSeasons: b.commit(setSeasons),
    commitSetUsers: b.commit(setUsers),
    commitSetRoles: b.commit(setRoles),
    commitSetEditedOrder: b.commit(setEditedOrder),
    commitSetEditedAccessory: b.commit(setEditedAccessory),
    commitSetEditedProduct: b.commit(setEditedProduct),
    commitSetEditedPromotion: b.commit(setEditedPromotion),
    commitSetEditedBanner: b.commit(setEditedBanner),
    commitSetEditedSeason: b.commit(setEditedSeason),
    commitSetEditedCode: b.commit(setEditedCode),
    commitSetEditedUser: b.commit(setEditedUser),
    commitSetEditedCodeOrders: b.commit(setEditedCodeOrders),

    dispatchLoadOrders: (b.dispatch(loadOrders) as (param?: any) => Promise<boolean>),
    dispatchSaveOrder: (b.dispatch(saveOrder) as (param?: any) => Promise<boolean>),
    dispatchUpdateOrderTreatedStatus: (b.dispatch(updateOrderTreatedStatus) as (param?: any) => Promise<boolean>),
    dispatchDeleteOrder: (b.dispatch(deleteOrder) as (param?: any) => Promise<boolean>),
    dispatchLoadCodes: (b.dispatch(loadCodes) as (param?: any) => Promise<boolean>),
    dispatchSaveCode: (b.dispatch(saveCode) as (param?: any) => Promise<boolean>),
    dispatchDeleteCode: (b.dispatch(deleteCode) as (param?: any) => Promise<boolean>),
    dispatchLoadPromotions: (b.dispatch(loadPromotions) as (param?: any) => Promise<boolean>),
    dispatchSavePromotion: (b.dispatch(savePromotion) as (param?: any) => Promise<boolean>),
    dispatchDeletePromotion: (b.dispatch(deletePromotion) as (param?: any) => Promise<boolean>),
    dispatchLoadBanners: (b.dispatch(loadBanners) as (param?: any) => Promise<boolean>),
    dispatchSaveBanner: (b.dispatch(saveBanner) as (param?: any) => Promise<boolean>),
    dispatchDeleteBanner: (b.dispatch(deleteBanner) as (param?: any) => Promise<boolean>),
    dispatchLoadSeasons: (b.dispatch(loadSeasons) as (param?: any) => Promise<boolean>),
    dispatchSaveSeason: (b.dispatch(saveSeason) as (param?: any) => Promise<boolean>),
    dispatchDeleteSeason: (b.dispatch(deleteSeason) as (param?: any) => Promise<boolean>),
    dispatchLoadUsers: (b.dispatch(loadUsers) as (param?: any) => Promise<boolean>),
    dispatchLoadRoles: (b.dispatch(loadRoles) as (param?: any) => Promise<boolean>),
    dispatchSaveUser: (b.dispatch(saveUser) as (param?: any) => Promise<boolean>),
    dispatchDeleteUser: (b.dispatch(deleteUser) as (param?: any) => Promise<boolean>),
    dispatchSaveProduct: (b.dispatch(saveProduct) as (param?: any) => Promise<boolean>),
    dispatchDeleteProduct: (b.dispatch(deleteProduct) as (param?: any) => Promise<boolean>),
    dispatchSaveAccessory: (b.dispatch(saveAccessory) as (param?: any) => Promise<boolean>),
    dispatchDeleteAccessory: (b.dispatch(deleteAccessory) as (param?: any) => Promise<boolean>),
    dispatchAddRole: (b.dispatch(addRole) as (param?: any) => Promise<boolean>),
    dispatchLoadEditedOrder: (b.dispatch(loadEditedOrder) as (param?: any) => Promise<boolean>),
}

export default admin